/*
 * App Global CSS
 * ----------------------------------------------------------------------------
 * Put style rules here that you want to apply globally. These styles are for
 * the entire app and not just one component. Additionally, this file can be
 * used as an entry point to import other CSS/Sass files to be included in the
 * output CSS.
 */

/* Do not change these file location, they're needed for SCSS features (mixins, includes, extend, etc) */
@import "bootstrap/scss/functions";
@import "bootstrap/scss/variables";
@import "bootstrap/scss/mixins";

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 400;
  src: url("/assets/Lato-Regular.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 200;
  src: url("/assets/Lato-Light.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 600;
  src: url("/assets/Lato-Bold.ttf");
}

@font-face {
  font-family: "Lato";
  font-style: normal;
  font-weight: 700;
  src: url("/assets/Lato-Black.ttf");
}

@mixin border-radius {
  border-radius: 10px !important;
}

html,
body {
  font-family: "Lato";
  background-color: var(--nav-primary);
}

h1 {
  font-size: var(--nav-font36) !important;
}

h2 {
  font-size: var(--nav-font24) !important;
}

h3 {
  font-size: var(--nav-font18) !important;
}

h4 {
  font-size: var(--nav-font16) !important;
}

h5 {
  font-size: var(--nav-font14) !important;
}

h6 {
  font-size: var(--nav-font12) !important;
}

.nav-rounded {
  @include border-radius;
}

.nav-border1 {
  border: 1px solid var(--nav-grey1);
}

.nav-border2 {
  border: 1px solid var(--nav-grey2);
}

.nav-border3 {
  border: 1px solid var(--nav-grey3);
}

.nav-border4 {
  border: 1px solid var(--nav-grey4);
}

.nav-border-white {
  border: 1px solid var(--nav-true-white);
}

.nav-border-primary {
  border: 1px solid var(--nav-primary);
}

.nav-border-secondary {
  border: 1px solid var(--nav-secondary);
}

.nav-bg-accent-blue {
  background-color: var(--nav-accent-blue) !important;
}

.nav-bg-primary {
  background-color: var(--nav-primary) !important;
}

.nav-bg-grey1 {
  background-color: var(--nav-grey1) !important;
}

.nav-bg-grey2 {
  background-color: var(--nav-grey2) !important;
}

.nav-bg-grey3 {
  background-color: var(--nav-grey3) !important;
}

.nav-bg-grey4 {
  background-color: var(--nav-grey4) !important;
}

.nav-bg-snow-white {
  background-color: var(--nav-snow-white) !important;
}

.nav-bg-error {
  background-color: var(--nav-error) !important;
}

.nav-bg-warning {
  background-color: var(--nav-warning) !important;
}

.nav-bg-success {
  background-color: var(--nav-success) !important;
}

.nav-primary {
  color: var(--nav-primary);
}

.nav-accent-blue {
  color: var(--nav-accent-blue);
}

.nav-secondary {
  color: var(--nav-secondary);
}

.nav-error {
  color: var(--nav-error) !important;
}

.nav-success {
  color: var(--nav-success);
}

.nav-warning {
  color: var(--nav-warning);
}

.nav-medium-risk {
  color: #CBA300;
}

.nav-snow-white {
  color: var(--nav-snow-white);
}

.nav-link {
  font-weight: 500;
  cursor: pointer;

  &:hover {
    text-decoration: underline !important;
  }
}

.nav-btn-primary {
  background: var(--nav-true-white);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border: 1px solid rgba(0, 119, 188, 0.3);
  color: var(--nav-secondary);
  padding: 5px 16px;

  &:hover {
    color: var(--nav-secondary);
    background-color: rgb(0, 119, 188, 0.3);
    border: 1px solid rgba(0, 119, 188, 0.3);
  }
}

.nav-btn-accent {
  background: var(--nav-accent-blue);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border-color: var(--nav-accent-blue);
  color: var(--nav-primary);
  padding: 5px 16px;

  &:hover {
    color: var(--nav-primary);
  }
}

.nav-link-accent {
  color: var(--nav-accent-blue);
  font-weight: 500;
  cursor: pointer;

  &:hover {
    color: #005384;
  }

  &:active {
    color: #0077bcb3;
  }
}

.nav-btn-error {
  background: var(--nav-error);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border-color: var(--nav-error);
  color: var(--nav-primary);
  padding: 5px 16px;

  &:hover {
    color: var(--nav-primary);
  }
}

.go-to-page-icon {
  color: var(--nav-grey3);

  &:hover {
    color: #005384;
  }

  &:active {
    color: #4ca0d0;
  }
}

.nav-btn-disabled {
  background: var(--nav-grey2);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border-color: var(--nav-grey2);
  color: var(--nav-primary);
  pointer-events: none;
  padding: 5px 16px;

  &:hover {
    color: var(--nav-primary);
  }
}

.font-weight-light {
  font-weight: 200 !important;
}

.font-weight-semi-bold {
  font-weight: 500 !important;
}

.font-weight-bold {
  font-weight: 600 !important;
}

.font-weight-black {
  font-weight: 700 !important;
}

.ckeditor {
  .ck {
    &.ck-editor {
      display: flex;
      flex-direction: column-reverse;

      .ck-toolbar {
        border: 0 !important;
        border-bottom-left-radius: 10px;
        border-bottom-right-radius: 10px;
        zoom: 0.8;
      }

      .ck-content {
        border: 0 !important;
        height: 70px !important;
        border-top-left-radius: 10px;
        border-top-right-radius: 10px;
        font-size: 14px;

        p {
          margin-bottom: 0;
        }
      }
    }
  }

  .save-reply {
    position: absolute;
    text-align: right;
    bottom: 3px;
    right: 10px;
  }
}

input[type=radio] {
  accent-color: var(--nav-accent-blue);
  width: 16px;
  height: 16px;
}

select,
textarea,
input:not([type="checkbox"]):not([type="radio"]):not([type="submit"]) {
  &.form-control-nav {
    @include border-radius;
    padding: 4px 8px;
    border: 1px solid var(--nav-grey1);

    &::placeholder {
      color: var(--nav-grey3);
    }
  }

  &.form-control-sm {
    height: 30px;
  }
}

textarea {
  &.form-control-sm {
    height: 60px !important;
  }
}

.or-checkbox {
  display: flex;
  justify-content: center;
  width: 100%;

  input[type="checkbox"] {
    opacity: 0;
    display: none;
    box-sizing: border-box;

    &:checked+label::before {
      font-family: "Font Awesome 6 Pro";
      content: "";
      text-align: center;
      font-weight: 600;
      line-height: 1.4;
      background-color: var(--nav-accent-blue);
      //border-color: var(--nav-accent-blue);
      color: white;
    }

    &:disabled+label::before {
      background-color: #e9ecef;
    }
  }

  label {
    display: inline-block;
    position: relative;
    cursor: pointer;
    font-size: 10px;
    color: var(--nav-primary);

    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 16px;
      height: 16px;
      top: 2px;
      left: 0px;
      //border: 1px solid var(--nav-accent-blue);
      border-radius: 3px;
      //background-color: var(--nav-primary);
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }

    span {
      margin-left: 30px;
    }
  }
}

.or-radio {
  display: flex;
  justify-content: start;
  width: 100%;

  label {
    position: relative;
    display: inline-block;
    cursor: pointer;
    border: 1px solid var(--nav-accent-blue);
    width: 16px;
    height: 16px;
    margin-right: 8px;
    margin-bottom: 0px;
    border-radius: 50%;

    &:before {
      border: 1px solid var(--nav-accent-blue);
      margin-bottom: 0px !important;
    }
  }

  input[type="radio"] {
    opacity: 0;
    display: none;
    box-sizing: border-box;

    &:disabled+label::before {
      background-color: #e9ecef;
    }

  }

  input[type="radio"]:checked+label {
    &:before {
      content: "";
      display: inline-block;
      position: absolute;
      width: 12px;
      height: 12px;
      margin: 1px;
      border-radius: 50%;
      border: 1px solid var(--nav-accent-blue);
      background-color: var(--nav-accent-blue);
      transition: border 0.15s ease-in-out, color 0.15s ease-in-out;
    }
  }
}

.nav-modal-style {

  &.nav-modal-tasks {
    top: 20% !important;
  }

  &.edit-notification-modal {
    max-width: 640px !important;

    .modal-content {
      height: 442px !important;
    }
  }

  &.alert-history-modal {
    max-width: 640px !important;
  }

  .modal-content {
    background-color: var(--nav-true-white);
    box-shadow: 0px 4px 4px rgba(1, 59, 92, 0.16);
    @include border-radius;
    border: 0;

    .modal-header {
      @include border-radius;
      background-color: var(--nav-true-white);
      border: 0;
    }

    .modal-body {
      padding: 10px 25px;
    }

    .modal-footer {
      border: 0;
      width: 100%;
      margin-bottom: 5px;
      @include border-radius;
      background-color: var(--nav-true-white);
    }
  }
}

.nav-modal {
  @media (min-width: 544px) {
    .modal-lg {
      max-width: 1200px;
    }

    .modal-md {
      max-width: 700px;
    }

    .modal-sm {
      max-width: 350px;
    }

    .modal-full {
      max-width: 95%;
      height: 90vh;

      .modal-body {
        max-height: 90vh;
        overflow: scroll;
      }
    }
  }
}

.modal-backdrop:has(+ .nav-modal-style) {
  &.show {
    opacity: 0.8 !important;
    background-color: var(--nav-grey1);
  }
}

.modal-backdrop:has(+ .modal .nav-sidebar-modal) {
  width: 435px !important;
}

.modal:has(.nav-sidebar-modal) {
  pointer-events: none !important;
}

.first-row {
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;

  &.first-col {
    border-top-right-radius: 0px;
  }

  &.last-col {
    border-top-left-radius: 0px;
  }
}

.last-row {
  border-bottom-left-radius: 10px;
  border-bottom-right-radius: 10px;

  &.first-col {
    border-bottom-right-radius: 0px;
  }

  &.last-col {
    border-bottom-left-radius: 0px;
  }
}

/* Navbar Notifications */
.notifications {
  .bell {
    width: 32px;
    height: 32px;
    background-color: transparent !important;

  }

  .count {
    position: absolute;
    top: -2px;
    right: -1px;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: var(--nav-success);

    &.blink {
      -webkit-animation: blink-animation 1.5s infinite;
      animation: blink-animation 1.5s infinite;
    }
  }
}

.modal:has(.nav-modal-new-vendor) {
  z-index: 1040 !important;
}

/* Navbar related projects dropdown */
bs-dropdown-container {
  .dropdown-related-projects {
    transform: translate(-25px, 5px) !important;
    border-radius: 0px 0px 10px 10px !important;
    border: 0.5px solid var(--nav-grey1) !important;
    background: var(--nav-true-white) !important;
    box-shadow: 0px 4px 4px 0px rgba(1, 59, 92, 0.16) !important;
    width: 100% !important;
    top: 100%;
    left: 0;
    z-index: 1000;
    float: left;
    min-width: 10rem;
    padding: 0.5rem 0;
    margin: 0.125rem 0 0;
    font-size: 1rem;
    color: #000;
    text-align: left;
    list-style: none;

    &.open {
      display: inline-table;
    }

    .dropdown-item {
      &:active {
        background-color: transparent !important;
      }

      &:hover {
        color: #16181b !important;
        text-decoration: none !important;
        background-color: #e9ecef !important;
      }
    }

    .fa-down-arrow {
      font-size: 9px;
      position: relative;

      &.open {
        transform: rotate(180deg);
        bottom: 3px;
      }
    }
  }
}

/* Notifications dropdown on top nav */
bs-dropdown-container {
  .notifications-content li {
    display: block;
    padding: 12px 20px;
    border-bottom: 1px solid #eee;
    text-align: left;

    span {
      font-size: 11px;
      color: #6f7277;
    }

    small {
      font-size: 10px;
    }

    .media {
      display: flex;
      align-items: flex-start;

      .media-body {
        flex: 1;
      }
    }

    .notification-icon {
      padding: 8px;
      border-radius: 100%;
      margin-right: 15px;
    }
  }

  .scrollbar {
    max-height: 400px;
  }
}

.breadcrumb {
  line-height: normal;
  padding-top: 0px;
}

.container-fluid {
  padding-left: 0;
  padding-right: 0;
}

.nav-content {
  margin-top: 88px;
  padding-left: 40px;
  padding-right: 24px;
}

/* Search bar design */
typeahead-container {
  &.dropdown-menu:has(.global-search-result) {
    left: -50px !important;
    min-width: 311px;
    max-width: 320px;
    border-radius: 10px 10px;
    border: 1px solid #F1F4FA;
    background: #FFF;
    box-shadow: 0px 4px 4px 0px rgba(1, 59, 92, 0.16);

    .list-group {
      margin-left: 5px;
      margin-right: 5px;

      .global-search-header {
        border-bottom: 1px solid #F1F4FA;
      }

      .list-group-item {
        &.active {
          border-radius: 10px;
          background: #F1F4FA;
          margin-top: 5px;
        }

        &.archived {
          background-color: var(--nav-grey1);

          &.active {
            border-radius: 10px;
            background: #F1F4FA;
          }
        }
      }
    }
  }
}

/** Modal Dialogs for Survey Tester **/
@media only screen and (min-width: 768px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(293px + -53.5%);
  }
}

@media only screen and (min-width: 992px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(297px + -53.5%);
  }
}

@media only screen and (min-width: 1200px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(313px + -53.5%);
  }
}

@media only screen and (min-width: 1600px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(326px + -53.5%);
  }
}

@media only screen and (min-width: 2100px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(334px + -53.5%);
  }
}

@media only screen and (min-width: 2500px) {
  .nav-sidebar-modal {
    top: 25%;
    left: calc(348px + -53.5%);
  }
}

.fak {
  &.fa-projectlist {
    &::before {
      content: "\e016";
    }
  }

  &.fa-dashboard {
    &::before {
      content: "\e017";
    }
  }

  &.fa-reportsperformance {
    &::before {
      content: "\e017";
    }
  }

  &.fa-reportsaudit {
    &::before {
      content: "\e017";
    }
  }

  &.fa-reportsreportbuilder {
    &::before {
      content: "\e04f";
    }
  }

  &.fa-tasklist {
    &::before {
      content: "\e018";
    }
  }

  &.fa-reportscleanid {
    &::before {
      content: "\e077";
    }
  }

  &.fa-reportsresponseid {
    &::before {
      content: "\e073";
    }
  }

  &.fa-workloadreports {
    &::before {
      content: "\e074";
    }
  }
}

.card {
  @extend .card;
  @include border-radius;
  background-color: white;

  .card-header {
    @include border-radius;
    border-bottom: 0px;
    padding: 16px;
  }

  .card-footer {
    @include border-radius;
    bottom: 0;
    border: 0;
    width: 100%;
  }
}

.dropdown {
  .dropdown-menu {
    width: max-content;
    box-shadow: 0px 4px 4px rgba(1, 59, 92, 0.16);
    @include border-radius;

    .dropdown-item {
      color: var(--nav-grey5) !important;

      &:hover {
        @include border-radius;
        background-color: var(--nav-grey4) !important;
        color: white !important;
      }

      &.disabledItem {
        color: var(--nav-grey2) !important;
        background-color: transparent !important;
      }
    }

    &.dropdown-menu-alerts {
      width: 280px !important;
    }
  }
}

typeahead-container {
  &.dropdown-menu {

    top: 2px !important;
    z-index: 100000 !important;

    .dropdown-item {

      &.active {
        color: #212529;
        background-color: #f1f3f7;
        cursor: pointer;
      }

      &:hover {
        background: #f1f3f7;
      }
    }
  }
}

/************************************************************
 Survey Tester CSS classes
*************************************************************/

/** Modal Dialogs for Survey Tester **/
@media only screen and (min-width: 768px) {
  .st-modal {
    top: 18%;
    left: 23px;
    margin: 0;

    &.survey-path {
      top: 5%;
    }
  }
}

@media only screen and (min-width: 992px) {
  .st-modal {
    top: 18%;
    left: 23px;
    margin: 0;

    &.survey-path {
      top: 5%;
    }
  }
}

@media only screen and (min-width: 1200px) {
  .st-modal {
    top: 18%;
    left: 23px;
    margin: 0;

    &.survey-path {
      top: 5%;
    }
  }
}

@media only screen and (min-width: 1600px) {
  .st-modal {
    top: 18%;
    left: 23px;
    margin: 0;

    &.survey-path {
      top: 5%;
    }
  }
}

@media only screen and (min-width: 2100px) {
  .st-modal {
    top: 18%;
    left: 23px;
    margin: 0;

    &.survey-path {
      top: 5%;
    }
  }
}

@media only screen and (min-width: 2500px) {
  .st-modal {
    top: 18%;
    left: 23px;
    margin: 0;

    &.survey-path {
      top: 5%;
    }
  }
}

survey-tester {
  .card {
    @extend .card;
    @include border-radius;
    background-color: var(--nav-primary);

    .card-header {
      @include border-radius;
      background-color: transparent;
      border-bottom: 0px;
    }

    .card-footer {
      border-radius: 0;
      bottom: 0;
      border: 0;
      width: 100%;
      background-color: var(--nav-primary);
    }
  }

  span,
  a {
    font-size: 14px;
  }

  a:hover {
    text-decoration: none;
  }

  .ckeditor {
    .ck {
      &.ck-editor {
        display: flex;
        flex-direction: column-reverse;

        .ck-toolbar {
          border: 0 !important;
          border-bottom-left-radius: 10px;
          border-bottom-right-radius: 10px;
          zoom: 0.8;
        }

        .ck-content {
          border: 0 !important;
          height: 70px !important;
          border-top-left-radius: 10px;
          border-top-right-radius: 10px;
          font-size: 14px;
        }
      }
    }

    .save-reply {
      position: absolute;
      text-align: right;
      bottom: 3px;
      right: 10px;
    }

  }
}

.modal-backdrop:has(+ .modal .st-modal) {
  width: 400px !important;
}

.modal:has(.st-modal) {
  pointer-events: none !important;
}

.st-modal-style {
  .modal-content {
    background-color: var(--nav-primary);
    border: 0;
    box-shadow: 0px 4px 4px rgba(1, 59, 92, 0.16);
    @include border-radius;

    .modal-header {
      @include border-radius;
      background-color: var(--nav-primary);
      border: 0;
    }

    .modal-body {
      padding: 10px 25px;
    }

    .modal-footer {
      border: 0;
      width: 100%;
      @include border-radius;
      background-color: var(--nav-primary);
    }
  }

  .ckeditor {
    .ck {
      &.ck-editor {
        display: flex;
        flex-direction: column-reverse;

        .ck-toolbar {
          border: 0 !important;
          border-bottom-left-radius: 10px !important;
          border-bottom-right-radius: 10px !important;
          zoom: 0.8;
        }

        .ck-content {
          border: 0 !important;
          height: 70px !important;
          border-top-left-radius: 10px !important;
          border-top-right-radius: 10px !important;
          font-size: 14px;
        }
      }

      &.ck-focused {
        box-shadow: none !important;
      }
    }
  }

  &.preview {
    .ckeditor {
      .ck {
        &.ck-editor {
          border: 1px solid var(--nav-grey1) !important;
          border-radius: 10px;

          .ck-content {
            border: none !important;
          }

          .ck-toolbar {

          }
        }
      }
    }
  }

  .attach-file {
    .upload {
      .upload-container {
        .border-container {
          padding: 5px !important;

          .fa-upload {
            position: relative;
            top: 1px;
          }

          .mainMsgRIDWOrkflow {
            font-size: 14px !important;
          }
        }
      }
    }
  }

  .attachments {
    .file {
      border-radius: 8px;
      border: 1px solid var(--Grey-1, #E0E0E0);
      background: rgba(224, 224, 224, 0.5);
      display: flex;
      padding: 4px;
      font-size: 13px;
      padding-left: 10px;
      padding-right: 10px;

      &.edit {
        background: rgba(224, 224, 224, 0.5) !important;
      }
    }
  }
}

.calendar-event-tooltip {
  position: absolute;
  background-color: #333;
  color: #fff;
  padding: 5px 10px;
  border-radius: 4px;
  font-size: 12px;
  white-space: nowrap;
  z-index: 1000;
  pointer-events: none;
  box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.3);
}

.st-modal {
  @media (min-width: 544px) {
    .modal-lg {
      max-width: 1200px;
    }

    .modal-md {
      max-width: 700px;
    }

    .modal-sm {
      max-width: 350px;
    }

    .modal-full {
      max-width: 95%;
      height: 90vh;

      .modal-body {
        max-height: 90vh;
        overflow: scroll;
      }
    }
  }
}

.tooltip {
  &.show {
    opacity: 0.98;
  }

  .tooltip-inner {
    background-color: var(--nav-grey4);
    opacity: 1;
    max-width: max-content;
  }
  &.project-name-tooltip .tooltip-inner{
    position:relative;
    top:-40px;
  }
  &.project-name-tooltip .tooltip-arrow{
    position:relative;
    top:-5px;
  }
  &.bs-tooltip-right {
    .tooltip-arrow {
      &:before {
        border-right-color: var(--nav-grey4) !important;
      }
    }
  }

  &.bs-tooltip-left {
    .tooltip-arrow {
      &:before {
        border-left-color: var(--nav-grey4) !important;
      }
    }
  }

  &.bs-tooltip-top,
  &.bs-tooltip-bottom {
    .tooltip-arrow {
      &:before {
        border-bottom-color: var(--nav-grey4) !important;
        border-top-color: var(--nav-grey4) !important;
      }
    }
  }

  &.tooltip-add-field {
    top: 37px !important;
  }

  &.tooltip-supportOn {
    top: 20px !important;
    left: -35px !important;
  }
}

.menu-navbar-dropdown {
  text-shadow: none;
  line-height: normal;
  background-color: white;
  border-radius: 10px;
  border: 1px solid var(--Outline, #F1F4FA);
  box-shadow: 0px 4px 4px 0px rgba(1, 59, 92, 0.08);
  width: 156px;
  padding: 8px 0px;
  top: 5px;

  .menu-container {
    overflow-y: auto;
    max-height: 350px;
    padding-left: 8px;
    padding-right: 9px;
    width: calc(100% + -1px);

    .menu-item-child {
      .menu-item-child-text {
        font-weight: 400;
        color: var(--nav-grey5);
        height: 32px;
        font-size: 14px;
        margin-left: 8px;
        padding-top: 7px;
      }

      .icon {
        width: 32px;
        height: 32px;
        padding: 8px;
        font-weight: 300 !important;
        border-radius: 10px;
      }

      &:hover {
        .icon {
          background-color: #013B5C1A;
          color: var(--nav-secondary)
        }

        .menu-item-child-text {
          color: #005384;
          font-weight: 600;
        }
      }

      &:active {
        .menu-item-child-text {
          color: #4ca0d0;
        }
      }
    }
  }
}


select {

  -webkit-appearance: none;
  -moz-appearance: none;
  background: transparent;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16' width='14' viewBox='0 0 448 512'%3E%3Cpath fill='%235d6a7d' d='M241 337c-9.4 9.4-24.6 9.4-33.9 0L47 177c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0l143 143L367 143c9.4-9.4 24.6-9.4 33.9 0s9.4 24.6 0 33.9L241 337z'/%3E%3C/svg%3E");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 60%;

  &.form-control-st {
    @include border-radius;
    padding: 4px 8px;
    border: 1px solid var(--nav-primary);
  }

  &.form-control-sm {
    height: 30px;
  }
}

.st-btn-primary {
  background: var(--nav-primary);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  @include border-radius;
  border-color: var(--nav-secondary);
  color: var(--nav-secondary);
  padding: 5px 16px;

  &:hover {
    color: var(--nav-secondary);
    background-color: rgb(0, 119, 188, 0.3);
    border: 1px solid rgba(0, 119, 188, 0.3);
  }
}

/************************************************************
 Dragula classes
 *************************************************************/

/* in-flight clone */
.gu-mirror {
  position: fixed !important;
  margin: 0 !important;
  z-index: 9999 !important;
  opacity: 0.8;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=80)";
  filter: alpha(opacity=80);
  pointer-events: none;
}

/* high-performance display:none; helper */
.gu-hide {
  left: -9999px !important;
}

/* added to mirrorContainer (default = body) while dragging */
.gu-unselectable {
  -webkit-user-select: none !important;
  -moz-user-select: none !important;
  -ms-user-select: none !important;
  user-select: none !important;
}

/* added to the source element while its mirror is dragged */
.gu-transit {
  opacity: 0.2;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=20)";
  filter: alpha(opacity=20);
}

/* DROPDOWN STATUS (KEEP THIS CSS OUTSIDE THE STATUS COMPONENT)) */
#dropdown-status.show {
  top: -2px !important;
  right: 0px;
}

.ck-powered-by-balloon {
  display: none !important;
}
